.bg {
    background-color: rgb(18, 4, 5, 0.95);
}
.bg-black {
    background-color: rgba(0, 0, 0, 0.7);
}

.bg-grey {
    background-color: rgba(127, 127, 127, 0.7);
}

.bg-white {
    background-color: rgba(255, 255, 255, 0.7);
}

.fg-black {
    color: rgba(0, 0, 0, 1.0);
}

.fg-grey {
    color: rgba(127, 127, 127, 1.0);
}

.fg-white {
    color: rgba(255, 255, 255, 1.0);
}

.fg-purple {
    color: rgba(128, 0, 128, 1.0);
}

.fg-violet {
    color: rgb(57, 36, 141, 1.0);
}

.fg-pink {
    color: rgb(255, 72, 255, 1.0);
}

.fg-yellow {
    color: rgba(255, 255, 0, 1.0)
}

.stroke-pink {
    -webkit-text-stroke: 1px rgb(255, 72, 255, 1.0);
}

.stroke-yellow {
    -webkit-text-stroke: 1px rgba(255, 255, 0, 1.0)
}

.stroke-black {
    -webkit-text-stroke: 1px rgba(0, 0, 0, 1.0)
}

.box-shadow {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 8px;
}

.app-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(../assets/images/ricardo-gomez-angel-9AdeEdYB2yk-unsplash.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    z-index: -1;
}

.app-content {
    position: absolute;
    top: 30px;
    left: 30px;
    width: calc(100vw - 60px);
    height: calc(100vh - 60px);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.scrollable{
    overflow-y: scroll;
    overflow-x: hidden;
}

.segment {
    padding: 00px 40px 20px 40px;
    color: hsl(0, 1.7%, 76.9%);
    position: relative;
    width: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.username {
    text-align: center;
    width: 100%;
    height: 150px;
}

.emphasis {
    font-weight: 700;
    font-stretch: expanded;
}

a {
    color: rgb(255, 72, 255, 1.0);
    text-decoration: none;
}

a:visited {
    color: rgb(116, 81, 255, 1.0);
}

a:hover {
    color: rgba(255, 255, 0, 1.0);
    text-decoration: underline;
    cursor: pointer;
}

.links {
    margin: 30px 0 80px 0;
}

.link-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: auto;
    height: 36px;
}

.link-container > div {
    margin: 3px 10px 0 0;
}
