.oblique {
    font-style: oblique;
}
.italic {
    font-style: italic;
}

.lighter {
    font-weight: 300;
}
.light {
    font-weight: 400;
}
.regular {
    font-weight: 500;
}
.bold {
    font-weight: 600;
}
.bolder {
    font-weight: 700;
}

.xxs {
    font-size: 0.5em;
}
.xs {
    font-size: 0.75em;
}
.sm {
    font-size: 0.875em;
}
.md {
    font-size: 1em;
}
.lg {
    font-size: 1.5em;
}
.xl {
    font-size: 2.125em;
}
.xxl {
    font-size: 3em;
}

@media screen and (max-width: 600px) {
    .xxs {
        font-size: 0.5em;
    }
    .xs {
        font-size: 0.625em;
    }
    .sm {
        font-size: 0.75em;
    }
    .md {
        font-size: 0.9em;
    }
    .lg {
        font-size: 1.1em;
    }
    .xl {
        font-size: 1.5em;
    }
    .xxl {
        font-size: 2em;
    }

}

.font-jura {
    font-family: "Jura", sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-style: normal;
}
